import media from '@src/style/media';
import { ThemeType } from '@src/types/theme';
import updatedMedia from './media';

const theme: ThemeType = {
	...media,
	breakpoint: {
		...updatedMedia,
	},
};

export default theme;

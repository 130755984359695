import qs from 'qs';

import { baseConfig } from '../api';
import {
	SignUpParamsType,
	SignInParamsType,
	RefreshParam,
	VerifyNumberParam,
	VerifyPhoneNumberParam,
	ProfileParamsType,
} from '@src/types/auth';

export const signInConfig = (data: SignInParamsType) =>
	baseConfig().post('user/signIn/', qs.stringify(data));

export const signUpConfig = (data: SignUpParamsType) =>
	baseConfig().post('user/signUp/', qs.stringify(data));

export const refreshConfig = (data: RefreshParam) =>
	baseConfig().post('refresh/', qs.stringify(data));

export const verifyPhoneNumberConfig = (data: VerifyPhoneNumberParam) =>
	baseConfig().post('auth/', qs.stringify(data));

export const verifyNumberConfig = (data: VerifyNumberParam) =>
	baseConfig(true).post('auth/verify', qs.stringify(data));

export const updateProfileConfig = (data: ProfileParamsType) =>
	baseConfig(true).post('user/setProfile/', qs.stringify(data));
import { css } from 'styled-components';

export type BreakPointLabel = 'labtop' | 'tablet' | 'mobile';
export enum BreakPointEnum {
	labtop,
	tablet,
	mobile,
}

export const BreakPoint = {
	labtop: 1199,
	tablet: 1023,
	mobile: 767,
};

export type BreakPointType = {
	labtop?: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	tablet?: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
	mobile?: (strings: TemplateStringsArray, ...expr: any[]) => TemplateStringsArray;
};

export default Object.keys(BreakPoint).reduce((acc, label): BreakPointType => {
	acc[label] = (strings: TemplateStringsArray, ...expr: any[]) => css`
		@media (max-width: ${BreakPoint[label]}px) {
			${css(strings, ...expr)};
		}
	`;
	return acc;
}, {});

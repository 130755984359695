import ReactGA from 'react-ga';

// server / client 구분을 위한 변수
const IS_BROWSER = typeof window !== 'undefined';

export function init(code) {
	// client-side에서만, GA가 initialize되지 않았을 때만 init
	if (IS_BROWSER && !window.GA_INITIALIZED && code) {
		// console.log('init');
		ReactGA.initialize(code);
		ReactGA.plugin.require('displayfeatures');
	}
}

export function pageview() {
	// console.log('pageView');
	ReactGA.set({ page: window.location.pathname });
	ReactGA.pageview(window.location.pathname);
}

export function event(category = '', action = '') {
	// console.log('event');
	if (category && action) {
		ReactGA.event({ category, action });
	}
}

export function exception(description = '', fatal = false) {
	// console.log('exception');
	if (description) {
		ReactGA.exception({ description, fatal });
	}
}

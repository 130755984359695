export enum ErrorCodeEnum {
	NULL_REFRESH_TOKEN,
	NOT_VALID_REFRESH_TOKEN,
	NULL_PRODUCTID_OPTIONID,
	NULL_IMPUID_MERCHANTUID,
	NULL_ACCESS_TOKEN,
	NOT_VALID_ACCESS_TOKEN,
	NOT_VALID_USER,
	DIFFERENT_AMOUNT_SALEPRICE,
	NOT_VALID_MERCHANTUID,
	NOT_IAMPORT_ACCESS,
	NULL_IAMPORT_AMOUNT,
	NOT_VALID_PHONENUMBER,
	NULL_TWILIO_VERIFICATION,
	PDF_WATERMARK_ERROR,
}

import axios, { AxiosError, AxiosResponse } from 'axios';
import { IncomingMessage } from 'http';

import { listConfig, reviewWriteConfig } from './config';
import { getCookie } from '@src/lib/util/cookie';
import { SignUpParamsType } from '@src/types/auth';
import { CustomError, HTTPError } from '@src/models/error/http';
import { ItemResponse } from '@src/types/me';

const list = async (req?: IncomingMessage) =>
	axios(listConfig(req))
		.then((res: AxiosResponse<ItemResponse>) => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response.data);
		});

const reviewWrite = async (orderItemId, data: { starRate; contents }) =>
	axios(reviewWriteConfig(orderItemId, data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response.data);
		});

const data = () => {
	const isLoggedIn = getCookie('isLoggedIn');
	let meData: SignUpParamsType = {
		name: null,
		email: null,
		providerId: null,
		providerType: null,
	};
	if (isLoggedIn) {
		const localData = JSON.parse(localStorage.getItem('userData'));
		if (!localData) {
			meData.name = getCookie('name');
			meData.email = getCookie('email');
		} else {
			meData = localData;
		}
	}

	return meData;
};

const meService = {
	list,
	reviewWrite,
	data,
};

export default meService;

import { Router } from 'next/router';
import { useState, useEffect } from 'react';
import _ from 'lodash';

import { gtmPageView } from '@src/lib/gtm';

const isDev = process.env.NODE_ENV === 'development';

//사용하기 전에 process.browser 확인 후 사용할 것
export const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = _.throttle(() => setWidth(window.innerWidth), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return width;
};

export const useWindowHeight = () => {
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		const handleResize = _.throttle(() => setHeight(window.innerHeight), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return height;
};

export const useScrollDirection = (initialDirection: string) => {
	const [direction, setDirection] = useState(initialDirection);
	const [position, setPosition] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			if (window.scrollY < 100) return;
			if (Math.abs(position - window.scrollY) > 15) {
				setDirection(position > window.scrollY ? 'up' : 'down');
				setPosition(window.scrollY);
			}
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return direction;
};

export const useScrollY = () => {
	const [scrollY, setScrollY] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			setScrollY(window.scrollY);
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return scrollY;
};

export const useResize = myRef => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const handleResize = _.throttle(() => {
			setWidth(myRef.current.offsetWidth);
			setHeight(myRef.current.offsetHeight);
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return { width, height };
};

export const useIsTablet = () => {
	const [isTablet, setIsTablet] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser) return;
			const { innerWidth } = window;
			if (innerWidth >= 1024) {
				setIsTablet(false);
			} else {
				setIsTablet(true);
			}
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return isTablet;
};

export const useGtmPageView = () => {
	useEffect(() => {
		if (!process.browser || isDev) return;

		const handleRouteChange = (url: string) => {
			gtmPageView(url);
		};

		Router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, []);
};

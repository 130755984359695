import { Cookies } from 'react-cookie';
import { IncomingMessage } from 'http';
import { Cookie, CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const setCookie = (key: string, value: Cookie, options?: CookieSetOptions) => {
	return cookies.set(key, value, { path: '/', ...options });
};

export const removeCookie = (key: string) => {
	return cookies.remove(key, { path: '/' });
};

export const getCookie = (key: string, req?: IncomingMessage) => {
	return req && !process.browser ? getCookieFromServer(key, req) : getCookieFromBrowser(key);
};

const getCookieFromBrowser = (key: string) => {
	return cookies.get(key);
};

const getCookieFromServer = (key: string, req: IncomingMessage) => {
	if (!req.headers.cookie) {
		return undefined;
	}
	const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`));
	if (!rawCookie) {
		return undefined;
	}
	return decodeURI(rawCookie.split('=')[1]);
};

import AuthService from '@src/services/auth';
import { ErrorCodeEnum } from '@src/types/error';

export type CustomError = {
	status: number;
	message: string;
	code: ErrorCodeEnum;
};

export class HTTPError extends Error {
	private statusCode: number;
	private errorCode: ErrorCodeEnum;

	constructor(errorData: CustomError) {
		super(); // 반드시 호출해야함
		this.name = `HTTPError`;
		this.statusCode = errorData.status;
		this.message = errorData.message;
		this.errorCode = errorData.code;

		Object.setPrototypeOf(this, HTTPError.prototype);
	}

	forceSignOut() {
		if (
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NOT_VALID_REFRESH_TOKEN ||
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NULL_REFRESH_TOKEN
		) {
			AuthService.signOut();
		}
	}

	async handleRefresh() {
		if (
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NULL_ACCESS_TOKEN ||
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NOT_VALID_ACCESS_TOKEN
		) {
			await AuthService.refresh();
		}
	}

	handlePhoneNumber() {
		if (
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NOT_VALID_PHONENUMBER ||
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NULL_TWILIO_VERIFICATION
		) {
			alert('메세지 전송과정에서 오류가 발생했습니다. 10분 뒤에 다시 인증요청 해주세요.');
		}
	}

	handlePhoneNumberVerifyNumber() {
		if (ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NOT_VALID_PHONENUMBER) {
			alert('잘못된 인증번호입니다. 제대로 입력해주세요.');
		} else if (
			ErrorCodeEnum[this.errorCode.toString()] === ErrorCodeEnum.NULL_TWILIO_VERIFICATION
		) {
			alert('메세지 전송과정에서 오류가 발생했습니다. 10분 뒤에 다시 인증요청 해주세요.');
		} else {
			alert(this.message);
		}
	}

	log() {
		console.log(this.toString());
	}

	alert() {
		alert(this.message);
	}
}

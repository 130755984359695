import moment from 'moment';

export const getExpireDate = (value: number, type: 'm' | 'd') => {
	const date = moment().utcOffset(9, true);
	const expireDate = date.add(value, type);

	return expireDate.toDate();
};

export const getYearMonthDay = value => {
	return moment(new Date(value)).format('YYYY.MM.DD');
};

export const getConsultingLeftDate = value => {
	const expireDate = moment(new Date(value)).add(1, 'month');
	const today = moment();
	const s = expireDate.diff(today, 'second');
	const m = expireDate.diff(today, 'minute');
	const h = expireDate.diff(today, 'hour');
	const d = expireDate.diff(today, 'day');

	return {
		d,
		h,
		m,
		s,
	};
};

export const formatLeftDate = ({ d, h, m, s }) => {
	if (d > 0) {
		return d + '일';
	}
	if (h > 0) {
		return h + '시간';
	}
	if (m > 0) {
		return m + '분';
	}
	if (s > 0) {
		return s + '초';
	} else {
		return 'expired';
	}
};
import * as prodLytics from './analytics/prod';
// import * as devLytics from './analytics/dev';

const isLocal = () => {
	return location.hostname === 'localhost';
};

const isDev = () => {
	return process.env.NODE_ENV !== 'production';
};

const isPreview = () => {
	return process.env.NEXT_PUBLIC_ENV === 'preview';
};

const initGA = (code, Router) => {
	// local이거나 development 환경일 땐 ga를 실행하지 않습니다.
	const shouldNotTrack = isLocal() || isDev() || isPreview();
	if (shouldNotTrack) {
		return;
	}

	// production or dev analytics
	// const analytics = shouldNotTrack ? devLytics : prodLytics;
	const analytics = prodLytics;

	// init
	analytics.init(code);
	// log page
	analytics.pageview();

	const handleRouteChange = url => {
		analytics.pageview();
	};

	Router.events.on('routeChangeComplete', handleRouteChange);

	// const previousCallback = Router.onBeforeHistoryChange;

	// Router.onBeforeHistoryChange = url => {
	// 	if (location.pathname === url) return;

	// 	if (typeof previousCallback === 'function') {
	// 		previousCallback();
	// 	}

	// 	analytics.pageview();
	// };
};

export default initGA;

import axios, { AxiosError, AxiosResponse } from 'axios';

import {
	signInConfig,
	signUpConfig,
	verifyPhoneNumberConfig,
	verifyNumberConfig,
	refreshConfig,
	updateProfileConfig,
} from './config';

import { setCookie, removeCookie, getCookie } from '@src/lib/util/cookie';
import { SignUpParamsType, ProfileParamsType } from '@src/types/auth';
import { getExpireDate } from '@src/lib/util/date';
import { CustomError, HTTPError } from '@src/models/error/http';

const signOut = async (alertText?: string) => {
	removeCookie('accessToken');
	removeCookie('refreshToken');
	removeCookie('name');
	removeCookie('email');
	removeCookie('isLoggedIn');
	removeCookie('isCompleteProfileing');
	removeCookie('job');
	removeCookie('career');
	removeCookie('interestList');
	localStorage.removeItem('userData');
	if (alertText) {
		alert(alertText);
	}
};

const signUp = async (data: SignUpParamsType) => {
	const res = await axios(signUpConfig(data));
	return res
}

const signIn = async (data: SignUpParamsType) => {
	const res = await axios(signInConfig(data)); // 존재하지 않는 사용자는 412 에러 반환됨 (412) (return 까지 가지 않고 돌아감)
	return await onLoginSuccess(res); // 로그인 성공의 경우 (200, 201)
};

const onLoginSuccess = async res => {
	if(res.status == '200' && res.data.result != '201'){
		const { accessToken, refreshToken, nickname, job, career, interestList } = res.data;
		setCookie('accessToken', accessToken);
		setCookie('refreshToken', refreshToken, { expires: getExpireDate(13, 'd') });
		setCookie('name', nickname);
		setCookie('isLoggedIn', true);

		setCookie('job', job);
		setCookie('career', career);
		setCookie('interestList', interestList);
	}else{
		signOut();
	}

	return res;
};

const verifyPhoneNumber = async (data: { phoneNumber: string }) =>
	axios(verifyPhoneNumberConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response.data);
		});

const verifyNumber = async (data: { phoneNumber: string; verifyNumber: string }) =>
	axios(verifyNumberConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response.data);
		});

const refresh = async () => {
	const refreshToken = getCookie('refreshToken');

	const signIn = ({ access, refresh, nickname }) => {
		setCookie('accessToken', access);
		setCookie('refreshToken', refresh);
		setCookie('name', nickname);
		setCookie('isLoggedIn', true);
	};

	await axios(refreshConfig({ refresh: refreshToken }))
		.then((res: AxiosResponse<{ access; refresh; nickname }>) => {
			const { access, refresh, nickname } = res.data;
			signIn({ access, refresh, nickname });
		})
		.catch((e: AxiosError<CustomError>) => {
			const { data } = e.response;
			const err = new HTTPError(data);
			err.forceSignOut();
		});
};

const updateProfile = async (data: ProfileParamsType): Promise<void> =>
	axios(updateProfileConfig(data))
		.then(res => res.data)
		.catch((e: AxiosError<CustomError>) => {
			throw new HTTPError(e.response.data);
		});

const AuthService = {
	signIn,
	signUp,
	signOut,
	verifyPhoneNumber,
	verifyNumber,
	refresh,
	updateProfile,
};

export default AuthService;

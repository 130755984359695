import React, { useEffect } from 'react';
import Router from 'next/router';
import { ThemeProvider } from 'styled-components';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import '@src/style/global.css';

import theme from '@src/theme/default';
import initGA from '../src/lib/ga';

import { useGtmPageView } from '@src/hooks/util';
import { initKakao } from '@src/lib/kakao';
import { initGtm } from '@src/lib/gtm';
import sentry from '@src/lib/util/sentry';
import { initLogrocket } from '@src/lib/logrocket';

import { isIE } from '@src/lib/util/browser'

import { getCookie } from '@src/lib/util/cookie';
import { useRouter } from 'next/router';
import AuthService from '@src/services/auth';

sentry.init();

function TopgimilApp({ Component, pageProps, err }) {

	if(!isIE()){
		// const isLoggedIn = getCookie('isLoggedIn');
		// 로그인 되어있는 경우,
		// if(isLoggedIn){
		// 	// refreshtoken이 유효한지 체크하고 유효하면 재로그인, 유효하지 않으면 강제 로그아웃
		// 	AuthService.refresh();
		// }

		useGtmPageView();

		useEffect(() => {
			initGA(process.env.NEXT_PUBLIC_GA_APP_ID, Router);
			initKakao();
			initGtm();
			initLogrocket();
		}, []);

		return (
			<ThemeProvider theme={theme}>
				<div className="app-container">
					<Component {...pageProps} err={err} />
				</div>
			</ThemeProvider>
		);
	}else{
		useEffect(() => {
			if (typeof window !== 'undefined') {
				alert('탑기밀은 인터넷 익스플로러를 지원하지 않고 있습니다.\n크롬, 사파리, 파이어폭스 등 다른 브라우저로 접속해주세요.\n감사합니다.');
			}
		}, []);

		return (
			<div></div>
		)
	}
}

export default TopgimilApp;

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import meService from '@src/services/me';

declare global {
	interface Window {
		isLogRocketInitialized: boolean;
	}
}
export const initLogrocket = () => {
	if (
		typeof window !== 'undefined' &&
		process.env.NODE_ENV === 'production' &&
		!window.isLogRocketInitialized
	) {
		LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);
		window.isLogRocketInitialized = true;
		// plugins should also only be initialized when in the browser
		setupLogRocketReact(LogRocket);
		identify();
	}
};

const identify = () => {
	const meData = meService.data();
	LogRocket.identify(meData.providerId, {
		name: meData.name,
		email: meData.email,
		providerType: meData.providerType,
	});
};
